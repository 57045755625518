@import "../../assets/scss/variable";

.Select__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    select {
        width: 100%;
        min-width: 100px;
        border-radius: 12px;
        background: #242328;
        padding: 15px 20px;
        align-items: center;
        gap: 5px;
        border: none;
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        appearance: none;
        /*  safari  */
        -webkit-appearance: none;
        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }
        option {
            text-transform: capitalize;
        }
    }
    &::after,
    &::before {
        --size: 0.3rem;
        content: "";
        position: absolute;
        right: 1rem;
        pointer-events: none;
    }

    &::after {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-top: var(--size) solid #fff;

        top: 37px;
    }

    &::before {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-bottom: var(--size) solid #fff;

        top: 20px;
    }
    &.SchoolRegistrationSuccess__terms__select {
        select {
            border-radius: 60px;
            background: #19181d;
            padding: 0;
            height: 60px;
            width: 140px;
            flex-shrink: 0;
            font-size: 15px;
            padding: 0 0.95rem;
        }
    }
    &.Product__qty{
       width: 75px;
       height: 50px;
       overflow: hidden;
       border-radius: 15px;
       select{
           width: 100%;
           height: 100%;
           font-size:15px;
       }
       &::after,
       &::before {
           --size: 0.2rem;
    }
    &::after {
        top: 30px;
    }
    }
}

@media screen and (max-width: 768px) {
    .Select__wrapper {
        &::after {
            border-left: var(--size) solid transparent;
            border-right: var(--size) solid transparent;
            border-top: var(--size) solid #fff;
            top: 55%;
        }

        &::before {
            border-left: var(--size) solid transparent;
            border-right: var(--size) solid transparent;
            border-bottom: var(--size) solid #fff;
            top: 35%;
        }
        &.SchoolRegistrationSuccess__terms__select {
            select {
                border-radius: 60px;
                background: #19181d;
                padding: 0;
                height: 42px;
                width: 125px;
                flex-shrink: 0;
                font-size: 15px;
                padding: 0 0.95rem;
            }
        }
    }
}
