.products__list__wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr) );
    gap: 20px;
    margin-bottom: 5rem;
    .product{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        img{
            width: 100px;
            height: 100px;
            object-fit: contain;
            background-color: #E3E3E3;border-radius: 12px;
        }
        .product__name{
            color: #fff;
            font-size: 14px;
            b{
                font-size: 15px;
            }
        }
    }
}
@media screen and (max-width: 425px) {
    .products__list__wrapper{
     
        .product{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 0.5rem;
            cursor: pointer;
            margin-bottom: 1.5rem;
            img{
                width: 100px;
                height: 100px;
                object-fit: contain;
                background-color: #E3E3E3;border-radius: 12px;
            }
            .product__name{
                color: #fff;
                font-size: 14px;
                b{
                    font-size: 15px;
                }
            }
        }
    }
}