@import "../../assets/scss/variable";

.FileSelect__wrapper {
    width: 100%;
    min-height: 60px;
  display: flex;
  align-items: center;
  color: #fff;
  gap: 1rem;
  border-radius: 12px;
  background: #242328;
  width: 100%;
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: #19181d;
    border-radius: 6px;
    padding: .5rem 1rem;
    font-size: 14px;
     margin-left: 1rem;
     height: 85%;
  }
}
