h3 {
    color: #fff;
    font-weight: 600;
}

h4, h5 {
    color: #fff;
}

p {
    color: #fff;
}

a:link {
    color: #fff;
    text-decoration: none;
}

a:visited {
    color: #fff;
    text-decoration: none;
}

.remove_margin {
    margin-bottom: 0px;
}

b {
    font-weight: 600;
}

.text-center {
    text-align: center;
}