.ClipBoardCopy__copyText__area {
    border-radius: 12px;
    background: #242328;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    color: #fff;
    overflow: hidden;
    span {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        overflow-x: auto;
        width: calc(100% - 44px);
        white-space: nowrap;

        &::-webkit-scrollbar {
            display: none;
        }

    }
    i {
        cursor: pointer;
        font-size: 24px;
    }
}
