* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

@font-face {
    font-family: "BlackMango";
    src: url("../fonts/BlackMango-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "BlackMango";
    src: url("../fonts/BlackMango-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "BlackMango";
    src: url("../fonts/BlackMango-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "BlackMango";
    src: url("../fonts/BlackMango-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "BlackMango";
    src: url("../fonts/BlackMango-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "BlackMango";
    src: url("../fonts/BlackMango-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "BlackMango";
    src: url("../fonts/BlackMango-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "BlackMango";
    src: url("../fonts/BlackMango-Thin.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}
