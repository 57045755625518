.DayCouter__wrapper {
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .DayCouter__counter__title {
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .DayCouter__counter__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        .couter__card {
            width: 59px;
            height: 59px;
            border-radius: 6px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            span {
                color: #323232;
                text-align: center;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 29px;
                &:last-child {
                    font-size: 10px;
                    margin: 0;
                    line-height: 10px;
                }
            }
        }
    }
}
