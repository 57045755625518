@import "../../assets/scss/variable";

.Model__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    .Model__content {
        width: 760px;
        height: 419px;
        padding: 2rem;
        border-radius: 24px;
        background: #3b3e58;
        overflow: hidden;
    }
    &.registrationPopup {
        .Model__content {
            height: 345px;
            border-radius: 24px;
            background: #19181d;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            h2 {
                color: #fff;

                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            p {
                color: #fff;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .action__buttons__container {
                gap: 2rem;
                button {
                    display: inline-flex;
                    height: 60px;
                    padding: 15px 20px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    border-radius: 12px;
                    border: none;
                    outline: none;
                    &.cancel {
                        background-color: $label-danger-color;
                        color: #fff;
                        text-align: justify;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                    &.submit {
                        background-color: #FC0;
                        color: #000000;
                        text-align: justify;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                }
            }
        }
    }
    &.productModel{
        .Model__content{
            width: 500px;
            height: 500px;
            background-color: rgba(25, 24, 29, 0.397);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 1rem;position: relative;
            i{
                font-size: 25px;
                color: #ffffff;
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
            }
            .productImage{
                width: 95%;
               height: 95%;
                 overflow: hidden;
                transition: all 0.5s ease-in-out;
                position: relative;
               
                &:hover{
                    --zoom:2;
                }
            img{
                width: 100%;
                height: 100%;
                position: absolute;
                -o-object-fit: cover;
                object-fit: cover; 
                aspect-ratio: 1;
                transform: scale(var(--zoom,1));
                transform-origin: var(--x) var(--y);
                transition: all 0.3s ease;
            }
            }

        }
    }
}

@media screen and (max-width: 760px) {
    .Model__wrapper .Model__content {
        width: 95%;
    }
}

@media screen and (max-width: 578px) {
    .Model__wrapper {
        &.registrationPopup {
            .Model__content {
                height: 400px;
                .action__buttons__container {
                    gap: 1rem;
                    flex-direction: column;
                }
            }
        }
    }
}
