@import "../../assets/scss/variable";

.SchoolRegistrationSuccess__school,
.SchoolRegistrationSuccess__school__text {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2rem;
}
.SchoolRegistrationSuccess__school__text {
    margin-top: 0;
    line-height: 0;
    font-size: 19px;
    &.qr{
        margin-top: 3rem;
    }
}

.SchoolRegistrationSuccess__terms {
    width: 100%;
    min-height: 250px;
    border-radius: 12px;
    background: #242328;
    margin-top: 1rem;
    padding: 1rem;
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .SchoolRegistrationSuccess__terms__headings {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
            color: #f00;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        span {
            font-size: 15px;
        }
    }
    ul {
        margin-bottom: 2rem;
        li {
            font-size: 20px;
        }
    }
    a {
        border-radius: 60px;
        background: #fc0;
        color: #323232;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 10px 20px;
        text-decoration: none;
    }
}

@media screen and (max-width: 991px) {
    .SchoolRegistrationSuccess__terms {
        .SchoolRegistrationSuccess__terms__headings {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            h3 {
                font-size: 25px;
            }
            span {
                font-size: 15px;
            }
        }
        ul {
            margin: 2rem 0;
            li {
                font-size: 16px;
            }
        }
        a {
            padding: 5px 10px;
        }
    }
}

@media screen and (max-width: 436px) {
    .SchoolRegistrationSuccess__school,
    .SchoolRegistrationSuccess__school__text {
        font-size: 15px;
    }

    .SchoolRegistrationSuccess__terms {
        width: 100%;
        min-height: 250px;
        border-radius: 12px;
        background: #242328;
        margin-top: 1rem;
        padding: 1rem;
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .SchoolRegistrationSuccess__terms__headings {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h3 {
                font-size: 18px;
                text-align: center;
            }
            span {
                font-size: 11px;
            }
        }
        ul {
            margin-bottom: 2rem;
            li {
                font-size: 10px;
            }
        }
        a {
            border-radius: 60px;
            font-size: 10px;
            padding: 5px 10px;
        }
    }
}
