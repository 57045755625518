@import "../../assets/scss/variable";
.IndividualRegistrationSuccess__terms {
    .IndividualRegistrationSuccess__terms__link {
        border-radius: 0px;
        padding: 0px;
        background-color: transparent;
        color: $label-warning-color;
        font-size: 20px;
    }
}

@media screen and (max-width: 991px) {
    .IndividualRegistrationSuccess__terms {
        .IndividualRegistrationSuccess__terms__link {
            font-size: 16px;
        }
    }
}
@media screen and (max-width: 436px) {
    .IndividualRegistrationSuccess__terms {
        .IndividualRegistrationSuccess__terms__link {
            font-size: 10px;
        }
    }
}
