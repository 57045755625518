.CommingSoon__wrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .CommingSoon__wrapper__hero {
        width: 100%;
        height: calc(100vh - 135px);
        overflow: hidden;
        background-image: url("../../assets/images/comingSoon.png");
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-position: 0 0;
        h1,
        span {
            font-family: BlackMango;
            color: #000;
            margin: 0 20px;
        }
        .CommingSoon__wrapper__hero__main__heading {
            font-size: 150px;
            text-align: center;
            font-style: normal;
            font-weight: 500;
            line-height: 9rem;
            color: black;
            text-shadow:
                -1px -1px 0 white,
                0   -1px 0 white,
                1px -1px 0 white,
                1px  0   0 white,
                1px  1px 0 white,
                0    1px 0 white,
                -1px  1px 0 white,
                -1px  0   0 white;
            span {
                font-size: 100px;
                font-weight: 400;
            }
        }
        .CommingSoon__wrapper__hero__sub__heading {
            font-size: 60px;
            text-align: center;
            font-style: normal;
            font-weight: 400;
            line-height: 4rem;
            margin-top: 5rem; 
            text-shadow:
            -1px -1px 0 white,
            0   -1px 0 white,
            1px -1px 0 white,
            1px  0   0 white,
            1px  1px 0 white,
            0    1px 0 white,
            -1px  1px 0 white,
            -1px  0   0 white;
            span {
                font-size: 30px;
                font-weight: 400;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .CommingSoon__wrapper {
        .CommingSoon__wrapper__hero {
            background-image: url("../../assets/images/ComingSoon-Mobile.jpg");
            background-position: 0 0;
            .CommingSoon__wrapper__hero__main__heading {
                font-size: 120px;
                line-height: 6rem;
                span {
                    font-size: 70px;
                }
            }
            .CommingSoon__wrapper__hero__sub__heading {
                font-size: 40px;
                text-align: center;
                font-style: normal;
                font-weight: 400;
                line-height: 2rem;
                margin-top: 2rem;
                span {
                    font-size: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 378px) {
    .CommingSoon__wrapper {
        .CommingSoon__wrapper__hero {
            .CommingSoon__wrapper__hero__main__heading {
                font-size: 60px;
                line-height: 4rem;
                span {
                    font-size: 30px;
                }
            }
            .CommingSoon__wrapper__hero__sub__heading {
                font-size: 40px;
                text-align: center;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem;
                margin-top: 2rem;
                span {
                    font-size: 15px;
                }
            }
        }
    }
}
