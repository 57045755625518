@import "../../assets/scss/variable";

.Registrations__wrapper {
    min-height: 100vh;
    overflow: hidden;
    background: url("../../assets/images/forumBackground.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    .Registrations__form__wrapper {
        background-color: #19181d;
        border-radius: 24px;

        .Registrations__form__title {
            color: #fff;
            font-family: "BlackMango";
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        h6 {
            color: #78797b;
        }
        ul {
            color: #fff;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .Registrations__form__links__wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            a {
                text-decoration: none;
                border-radius: 60px;
                background: #fc0;
                padding: 0.5rem 1rem;
                color: #000;
                font-weight: bold;
            }
        }
        .TeamRegistration__title {
            color: #78797b;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .columnInputWrapper {
            padding: 0 1rem 0 0 0;
        }
        .inputWrapper {
            display: block;
            width: 100%;
            label {
                color: #78797b;
            }
        }
        button.submit {
            border-radius: 12px;
            background: #fc0;
            height: 60px;
            border: none;
            outline: none;
            color: #19181d;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        table {
            tbody {
                tr {
                    &:first-child{
                     .text-edit {
                        vertical-align: text-top;
                     }   
                    }
                    td {
                        color: #fff;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
            &.TShirts__table{
                tr{
                    td{
                        padding: .5rem 1rem;
                        color: #ffffff;
                        border: 1px solid #fff;
                        text-align: center;
                    }
                }
                font-size: 17px;
            }
        }

        Select {
            min-width: 130px;
        }
        .formSubmitted__area{
            h3{
                font-family: "BlackMango";
            }
        }
        .banner{
            width: 100%;
            object-fit: cover;
           margin-bottom: 2rem;
        }
    }
}

@media screen and (max-width: 991px) {
    .Registrations__wrapper {
        .Registrations__form__wrapper {
            .columnInputWrapper {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .Registrations__wrapper {
        .Registrations__logo {
            width: 50%;
        }
        .Registrations__form__wrapper {
            .Registrations__form__title {
                font-size: 25px;
                margin-top: 1rem;
            }
            h6 {
                font-size: 14px;
            }
            ul {
                font-size: 11px;
            }
            .Registrations__form__links__wrapper {
                flex-direction: column;
                margin-top: 1rem;
                a {
                    width: 100%;
                    font-size: 11px;
                    text-align: center;
                }
            }
            .inputWrapper {
                label {
                    font-size: 14px;
                }
                .input__group {
                    flex-direction: column;
                }
            }
            button.submit {
                height: 50px;
                font-size: 15px;
            }
        }
    }
}
