.primary__header {
    width: 100vw;
    height: 100px;
    overflow: hidden;
    padding: 0 2rem;
    .Header__mobile {
        display: none;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        i {
            font-size: 35px;
        }
    }
    .Header__navBar {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            li {
                list-style: none;
                margin: 0 0.5rem;
            }
            &.primary__header__links {
                li {
                    margin: 0 0.75rem;
                    a {
                        text-decoration: none;
                        position: relative;

                        span {
                            color: #000;
                            font-size: 20px;
                            font-weight: 400;
                            text-transform: uppercase;
                            padding: 0.5rem;
                            border-radius: 10px;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            left: -0.75rem;
                            top: 50%;
                            width: 5px;
                            height: 5px;
                            background-color: #000;
                            border-radius: 50%;
                        }

                        &:hover {
                            span {
                                background-color: #000000;
                                color: #ffffff;
                            }
                        }
                    }

                    &:first-child {
                        a {
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
            &.primary__header__social {
                li {
                    background-color: #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                    &:hover {
                        background-color: #ffffff;
                        border: 1px solid #000;
                        a {
                            color: #000;
                        }
                        transition: all 0.5s ease-in-out;
                    }
                    a {
                        color: #fff;
                        font-size: 18px;
                        padding: 0;
                        margin: 0;
                        margin-top: 0.1rem;
                        text-decoration: none;
                    }
                    &:last-child {
                        width: auto;
                        height: auto;
                        background-color: transparent;
                        a {
                            color: #000;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: uppercase;
                        }
                    }
                }

                .no-border {
                    border-style: none;
                    &:hover {
                        border-style: none;
                    }
                }
            }
        }
        .registration__form__link {
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: normal;
            text-transform: uppercase;
            text-decoration: none;
            &:hover {
                color: #000;
                span {
                    background-color: #ffffff;
                    border: 1px solid #000;
                    color: #000;
                    transition: all 0.5s ease-in-out;
                }
                transition: all 0.5s ease-in-out;
            }
            span {
                border-radius: 60px;
                color: #fff;
                padding: 0.4rem 1rem;
                background-color: #000;
                margin: 0rem;
                &:last-child {
                    padding: 0.4rem 0.6rem;
                    i {
                        transform: rotate(135deg);
                    }
                }
            }
        }
    }
    &[data-theme="dark"] {
        .Header__mobile {
            i {
                color: #fff;
            }
        }

        .Header__navBar {
            ul {
                &.primary__header__links {
                    li {
                        margin: 0 0.75rem;
                        a {
                            span {
                                color: #ffffff;
                            }

                            &::before {
                                background-color: #ffffff;
                            }
                            &:hover {
                                span {
                                    background-color: #ffffff;
                                    color: #000000;
                                }
                            }
                        }
                    }
                }
                &.primary__header__social {
                    li {
                        background-color: #ffffff;

                        a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #000000;
                            text-decoration: none;
                        }
                        &:hover {
                            background-color: transparent;
                            border: 1px solid #fff;
                            a {
                                color: #fff;
                            }
                            transition: all 0.5s ease-in-out;
                        }
                        &:last-child {
                            width: auto;
                            height: auto;
                            background-color: transparent;
                            color: #ffffff;
                        }
                    }

                    .no-border {
                        border-style: none;
                        &:hover {
                            border-style: none;
                        }
                    }
                }
            }
            .registration__form__link {
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: normal;
                text-transform: uppercase;
                text-decoration: none;
                &:hover {
                    color: #fff;
                    span {
                        background-color: transparent;
                        border: 1px solid #fff;
                        color: #fff;
                    }
                    transition: all 0.5s ease-in-out;
                }
                span {
                    border-radius: 60px;
                    color: #000000;
                    background-color: #ffffff;

                    i {
                        transform: rotate(135deg);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1190px) {
    .primary__header {
        .Header__navBar {
            ul {
                &.primary__header__links {
                    li {
                        a {
                            font-size: 18px;
                        }
                    }
                }
                &.primary__header__social {
                    li {
                        width: 25px;
                        height: 25px;

                        &:last-child {
                            a {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
            .registration__form__link {
                span {
                    font-size: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .primary__header {
        .Header__mobile {
            display: flex;
            .logo {
                width: 200px;
                height: auto;
                object-fit: cover;
            }
        }
        .Header__navBar {
            position: fixed;
            z-index: 999;
            top: 0;
            left: -100%;
            width: 75%;
            height: 100vh;
            overflow: hidden;
            justify-content: center;
            flex-direction: column;
            background-color: #000000;
            transition: all 0.5s ease-in-out;
            overflow: hidden;
            .logo {
                display: none;
            }
            &.active {
                left: 0;
            }
            ul {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                li {
                    list-style: none;
                    margin: 1rem 0.5rem;
                }
                &.primary__header__links {
                    li {
                        margin: 1rem 0;
                        a {
                            span {
                                border-radius: 60px;
                                color: #fff;
                                padding: 0;
                                margin: 0;
                            }
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
                &.primary__header__social {
                    flex-direction: row;
                    padding: 0;
                    li {
                        background-color: transparent;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        width: auto;
                        height: auto;
                        width: 35px;
                        height: 35px;
                        background-color: #fff;
                        a {
                            color: #000;
                            font-size: 20px;
                            padding: 0;
                            margin: 0;
                        }
                        &:last-child {
                            display: none;
                            a {
                                color: #000;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
            .registration__form__link {
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: normal;
                text-transform: uppercase;
                text-decoration: none;
                width: 85%;
                text-align: center;
                span {
                    border-radius: 60px;
                    color: #000;
                    padding: 0.2rem 0.5rem;
                    background-color: #fff;
                    font-size: 20px;
                    margin: 0;
                    &:last-child {
                        margin: -3.2px;
                    }
                    i {
                        transform: rotate(135deg);
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 510px) {
    .primary__header {
        .Header__navBar {
            ul {
                &.primary__header__links {
                    li {
                        margin: 1rem 0;
                        a {
                            font-size: 15px;
                        }
                    }
                }
            }
            .registration__form__link {
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                text-decoration: none;
                span {
                    border-radius: 60px;
                    color: #000;
                    padding: 0.2rem 0.5rem;
                    background-color: #fff;
                    margin: 0 0.2rem;
                    font-size: 15px;
                    i {
                        transform: rotate(135deg);
                    }
                }
            }
        }
    }
}
