@import "../../assets/scss/variable";
footer {
    width: 100%;
    height: 240px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        &:last-child {
            font-size: 14px;
            line-height: 20px; /* 142.857% */
        }
    }
}

@media screen and (max-width: 767px) {
    footer {
        p {
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
        }
    }
}
